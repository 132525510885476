import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import { Dropdown, Modal, Tabs, Popover, Toggle } from "tailwindcss-stimulus-components"
import Flatpickr from 'stimulus-flatpickr'

require("flatpickr/dist/flatpickr.css")

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)

application.load(definitionsFromContext(context))
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('flatpickr', Flatpickr)
